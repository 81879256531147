@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

/* set the color variables for background and main */
:root {
  --background: white;
  --main: #2d3f47;
  --font: 'Comfortaa', sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body::after{
   position: absolute; width:0; height:0; overflow:hidden; z-index:-1;
   content: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png)
}

#root, #root > *:not(.Toastify) {
  width: 100vw;
  height: 100%;
  background: var(--background);
  overflow-y: scroll;
}

.home, .stats, .status {
  color: var(--main);
  font-family: var(--font);
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home > * {
  max-width: 90vw;
}

.home img {
  width: 16rem;
}

.home .title {
  font-size: 3rem;
}

.home .text {
  text-align: center;
}

.stats .summary, .status .summary {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem;
}

.stats .partials, .stats .total, .status .partials, .status .total {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.stats .partials, .status .partials {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.stats .total, .status .total {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.home .add-year {
  width: 80vw;
  display: flex;
  margin-top: 1rem;
}

.home .add-year input {
  flex: 1;
}

.home .content {
  height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.add {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.add > * {
  max-width: 90vw;
}

.add > .form {
  flex: 1;
}

.add .types {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.2rem;
}

.add .types .type-button {
  width: 3rem;
  aspect-ratio: 1/1;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.2rem;
  margin: 0.2rem 0;
  box-sizing: border-box;
  border: var(--main) 1px solid;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add .types .type-button img {
  width: 100%;
  aspect-ratio: 1/1;
}

.add .types .type-button .icon {
  width: 100%;
  aspect-ratio: 1/1;
  fill: var(--main);
  font-size: 2rem;
  line-height: 2.2rem;
  font-family: var(--font);
}

.add .types .type-button.selected {
  background: var(--main);
  color: var(--background);
  border: none;
}

.status {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status .CircularProgressbar {
  margin: 1rem;
  width: calc(80% - 2rem);
}

.stats {
  width: 100%;
  height: 100%;
  overflow: scroll;
  text-align: center;
  padding-top: 1rem;
}

.recharts-cartesian-axis-tick * {
  font: 1rem var(--font);
}

.recharts-responsive-container {
  margin: 1rem 5%;
  width: 90%;
  height: 40%;
}

.stats .footer {
  height: 3rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--main) 0px -2px 5px 1px;
}

.title {
  font: bold 2rem var(--font);
  text-align: center;
  color: var(--main);
}

.sub-title {
  font: bold 1.5rem var(--font);
  color: var(--main);
}

.data, .text {
  font: bold 1rem var(--font);
  color: var(--main);
}

.loader {
  background: var(--background)70;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: var(--main);
  line-height: 2em;
  font-size: 1.2rem;
  text-align: center;
}

.button {
  font: bold 1.5rem var(--font);
  text-decoration: none;
  padding: 0.5em 1em;
  margin: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: var(--background);
  border: var(--main) 2px solid;
  color: var(--main);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.button.inline {
  display: inline-block;
}

.button:not(.inline) {
  min-width: 80vw;
}

.button.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.button.primary {
  color: var(--background);
  background: var(--main);
}

.button.link {
  border: none;
  text-decoration: underline;
}

.button.right {
  text-align: right;
}

.button.left {
  text-align: left;
}

.button.small {
  font-size: 1rem;
  min-width: 8em;
}

.button.tiny {
  font-size: 0.7rem;
  min-width: 6em;
}

.buttons {
  display: flex;
  justify-content: center;
}

.buttons .button:not(:last-child) {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttons .button:not(:first-child) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button svg {
  vertical-align: middle;
}

.form {
  display: grid;
  grid-template-columns: 3rem, 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.form .done {
  grid-column: 1 / 3;
  text-align: center;
  width: 100%;
  margin: 0;
}


input[type="text"], input[type="date"], input[type="number"], textarea, select {
  font: 1rem var(--font);
  text-decoration: none;
  box-sizing: border-box;
  background: var(--background);
  border-bottom: var(--main) 2px solid;
  color: var(--main);
  font-weight: bold;
  outline: none;
  box-shadow: none;
  inset: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

textarea {
  border-top: var(--main) 2px solid;
  border-right: var(--main) 2px solid;
  border-left: var(--main) 2px solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

input[type="text"]::placeholder {
  color: var(--main);
  opacity: 0.5;
}
